import service from "@/utils/ajax";

//用户登录-授权
export const customerServiceAuth = (data) => {
    return service({
        url: "/v1/customerService/customerServiceAuth",
        method: "POST",
        data
    })
}


export const userList = (params) => {
    return service(
        {
            url: '/v1/adminUser/sysgetUser',
            method: 'get',
            params
        }
    )
}

export const deleteAdminUser = (data) => {
    return service(
        {
            url: '/v1/adminUser/deleteAdminUser',
            method: 'post',
            data
        }
    )
}

export const sysRegister = (data) => {
    return service(
        {
            url: '/v1/adminUser/sysRegister',
            method: 'post',
            data
        }
    )
}

export const editAdminUser = (data) => {
    return service(
        {
            url: '/v1/adminUser/editAdminUser',
            method: 'post',
            data
        }
    )
}

export const getUserInfo = (params) => {
    return service(
        {
            url: '/v1/adminUser/sysUpUserInfo',
            method: 'get',
            params
        }
    )
}
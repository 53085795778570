<script setup>
import {onMounted, ref} from "vue";
import {addKeyword, delKeyword, getEsKeywordMsgList, getKeywordList, updateKeyword} from "@/api/keyword";
import {formatDate} from "@/utils/Fun";
import {ElMessage, ElMessageBox} from "element-plus";
//表单
const formData = ref({})
const resetForm = () => {
	formData.value = {}
	getList()
}
// table
const total = ref(0)
const tableData = ref([])
// 分页
const handleSizeChange = () => {

}
const handleCurrentChange = (page) => {
	formData.value.page = page
	getList()
}

onMounted(() => {
	getList()
})
const getList = async () => {
	try {
		const cloneForm = {...formData.value}
		if (cloneForm.status === 99) delete cloneForm.status
		const data = await getKeywordList(cloneForm)
		total.value = data.data.total
		tableData.value = data.data.data
	} catch (e) {
		console.log(e)
	}
}
const handleDelete = (row) => {
	try {
		ElMessageBox.confirm(
				'确认删除该账号?',
				'提示',
				{
					confirmButtonText: '删除',
					cancelButtonText: '取消',
					type: 'warning',
				}
		).then(async () => {
			await delKeyword({_id: row._id})
			ElMessage.success('删除成功')
			await getList()
		}).catch(err => {
			console.log(err)
		})
	} catch (e) {
		console.log(e)
	}
}
// 添加用户
const addUserVisible = ref(false)
const addFormData = ref({})
const addUser = () => {
	addUserVisible.value = true
}
const addSubmit = async () => {
	if (!addFormData.value.text) {
		ElMessage.warning('请输入关键词')
		return
	}
	try {
		await addKeyword(addFormData.value)
		ElMessage.success('添加成功')
		addUserVisible.value = false
		await getList()
	} catch (e) {
		ElMessage.warning(e)
	}
}
// 编辑
const editFormData = ref({})
const editUserVisible = ref(false)
const handleEdit = (row) => {
	editUserVisible.value = true
	editFormData.value = row
}
const editSubmit = async () => {
	if (!editFormData.value.appKey) delete editFormData.value.appKey
	try {
		editFormData.value.id = editFormData.value._id
		await updateKeyword(editFormData.value)
		ElMessage.success('编辑成功')
		editUserVisible.value = false
		await getList()
	} catch (e) {
		console.log(e)
	}
}
//
const checkMsgVisible = ref(false)
const checkMsgTableList = ref([])
const checkMsgTotal = ref(0)
const loading = ref(false)
const checkMsgPage = ref(1)
const checkMsgLimit = ref(10)
const activeRow = ref({})
const checkMsgList = async (row) => {
	try {
		activeRow.value = row
		loading.value = true
		checkMsgVisible.value = true
		const list = await getEsKeywordMsgList({text: row.text, page: checkMsgPage.value, limit: checkMsgLimit.value})
		checkMsgTableList.value = list.data
		checkMsgTotal.value = list.total
		loading.value = false
	} catch (e) {
		console.log(e)
		loading.value = false
	}
}
const checkMsgHandleCurrentChange = (page) => {
	checkMsgPage.value = page
	checkMsgList(activeRow.value)
}
</script>

<template>
	<div class="domain-list">
		<div class="domain-manage">
			<div class="top-form">
				<el-form :model="formData" label-position="top" inline style="width: 100%">
					<el-row style="width: 100%">
						<el-col :span="19">
							<el-row>
								<el-form-item label="关键词：">
									<el-input v-model="formData.text" placeholder="请输入关键词"></el-input>
								</el-form-item>
								<el-form-item label="状态：">
									<el-select placeholder="请选择状态" v-model="formData.status" style="width: 196px">
										<el-option label="全部" :value="99"></el-option>
										<el-option label="停用" :value="0"></el-option>
										<el-option label="正常" :value="1"></el-option>
									</el-select>
								</el-form-item>
							</el-row>
						</el-col>
						<el-col :span="5" style="display: flex;align-items: flex-end; padding-bottom: 20px">
							<el-button type="primary" @click="getList">查询</el-button>
							<el-button type="primary" plain @click="resetForm">重置</el-button>
							<el-button type="success" plain @click="addUser">添加</el-button>
						</el-col>
					</el-row>
				</el-form>
			</div>
			<div>
				<el-table border :data="tableData" style="width: 100%;">
					<el-table-column label="序号" type="index" width="80px" align="center"></el-table-column>
					<el-table-column label="关键词" prop="text" align="center"></el-table-column>
					<el-table-column label="创建人" prop="createUser" align="center"></el-table-column>
					<el-table-column label="状态" width="80px" align="center">
						<template #default="scope">
							<el-tag v-if="scope.row.status === 1" type="success">正常</el-tag>
							<el-tag v-else type="danger">停用</el-tag>
						</template>
					</el-table-column>
					<el-table-column
							label="创建时间"
							prop="createTime"
							align="center"
							:formatter="row=> row.createTime?formatDate(row.createTime):'-'"
					></el-table-column>
					<el-table-column
							label="更新时间"
							prop="updateTime"
							align="center"
							:formatter="row=> row.updateTime?formatDate(row.updateTime):'-'"
					></el-table-column>
					<el-table-column label="操作" width="300px" align="center">
						<template #default="scope">
							<el-button type="primary" size="small" @click="handleEdit(scope.row)">编辑</el-button>
							<el-button
									type="danger"
									size="small"
									@click="handleDelete(scope.row)"
									v-if="scope.row.username !== 'admin'"
							>删除
							</el-button>
							<el-button size="small" type="primary" @click="checkMsgList(scope.row)">查看触发消息</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="pagination" v-if="total">
				<el-pagination
						v-model:current-page="formData.page"
						:page-size="formData.limit"
						background
						layout="total, prev, pager, next"
						:total="total"
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
				/>
			</div>
		</div>
	</div>
	<!--	添加用户dialog-->
	<el-dialog title="添加" v-model="addUserVisible" width="500px">
		<el-form :model="addFormData" label-position="top" style="width: 100%">
			<el-form-item label="关键词：" required>
				<el-input v-model="addFormData.text" placeholder="请输入关键词"></el-input>
			</el-form-item>
		</el-form>
		<template #footer>
			<span class="dialog-footer">
				<el-button @click="addUserVisible = false">取 消</el-button>
				<el-button type="primary" @click="addSubmit">确 定</el-button>
			</span>
		</template>
	</el-dialog>
	<!--	编辑用户-->
	<el-dialog title="编辑" v-model="editUserVisible" width="500px">
		<el-form :model="editFormData" label-position="top" style="width: 100%">
			<el-form-item label="关键词：" required>
				<el-input v-model="editFormData.text" placeholder="请输入关键词"></el-input>
			</el-form-item>
			<el-form-item label="状态：">
				<el-select placeholder="请选择应用状态" v-model="editFormData.status" style="width: 196px">
					<el-option label="正常" :value="1"></el-option>
					<el-option label="停用" :value="0"></el-option>
				</el-select>
			</el-form-item>
		</el-form>
		<template #footer>
			<span class="dialog-footer">
				<el-button @click="editUserVisible = false">取 消</el-button>
				<el-button type="primary" @click="editSubmit">确 定</el-button>
			</span>
		</template>
	</el-dialog>
	<!--	查看触发关键词得消息列表-->
	<el-dialog title="查看触发消息" v-model="checkMsgVisible" v-if="checkMsgVisible" width="1000px">
		<el-table border :data="checkMsgTableList" style="width: 100%; height: 500px" v-loading="loading">
			<el-table-column label="序号" type="index" width="80px" align="center"></el-table-column>
			<el-table-column label="应用KEY" prop="appKey" align="center"></el-table-column>
			<el-table-column label="发送人ID" prop="formID" align="center"></el-table-column>
			<el-table-column label="接收人ID" prop="toID" align="center"></el-table-column>
			<el-table-column label="消息内容" prop="msg" align="center"></el-table-column>
			<el-table-column label="关键字" prop="keyword" align="center"></el-table-column>
			<el-table-column label="消息时间" prop="msgTime" align="center"/>
		</el-table>
		<!--	分页	-->
		<div class="pagination" v-if="total">
			<el-pagination
					v-model:current-page="checkMsgPage"
					:page-size="checkMsgLimit"
					background
					layout="total, prev, pager, next"
					:total="checkMsgTotal"
					@current-change="checkMsgHandleCurrentChange"
			/>
		</div>
	</el-dialog>
</template>

<style scoped lang="scss">
.domain-list {
	flex: 1;
	display: flex;
	padding: 20px;
	box-sizing: border-box;
}

.left-list {
	flex: 0 0 256px;
	background: #fff;
	border-radius: 8px;
	padding: 20px;
	box-sizing: border-box;

	.title {
		margin: 0 0 10px;
		color: #3A3F63;
		font-size: 18px;
		font-weight: 600;
	}

	.list {
		.item {
			padding: 12px 14px;
			color: #3A3F63;
			border-radius: 8px;
			margin-bottom: 10px;

			&.active {
				background: #F5F5FA;
			}

			&:hover {
				background: #F5F5FA;
				cursor: pointer;
			}
		}
	}
}

.domain-manage {
	flex: 1;
	background: #fff;
	border-radius: 8px;
	margin-left: 18px;
	padding: 20px;

	.table-tools {
		display: flex;
		align-items: center;
		margin: 0 0 10px;

		.title {

			color: #3A3F63;
			font-size: 16px;
			font-weight: 600;
		}

		.btns {
			flex: 1;
			display: flex;
			justify-content: flex-end;
		}
	}


}

.pagination {
	display: flex;
	justify-content: flex-end;
	padding-top: 20px;
}
</style>
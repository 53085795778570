import service from "@/utils/ajax";

export const applicationList = (params) => {
    return service(
        {
            url: '/v1/applicetion/findApp',
            method: 'get',
            params
        }
    )
}

export const applicationDel = (data) => {
    return service(
        {
            url: '/v1/applicetion/deleteApp',
            method: 'post',
            data
        }
    )
}

export const applicationAdd = (data) => {
    return service(
        {
            url: '/v1/applicetion/createApp',
            method: 'post',
            data
        }
    )
}

export const applicationUpdate = (data) => {
    return service(
        {
            url: '/v1/applicetion/updateApp',
            method: 'post',
            data
        }
    )
}
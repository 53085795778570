<script setup>
import {computed, onMounted} from "vue";

import {useStore} from "vuex";

const store = useStore()

const userInfo = computed(() => store.getters.userInfo)

onMounted(() => {

})

</script>

<template>
	<div class="board">
		<p class="welcome">欢迎回来，{{ userInfo.nickname || '未知用户' }}👏</p>


	</div>
</template>

<style scoped lang="scss">
.board {
	flex: 1;
	padding: 22px;
	background: #fff;
}

.welcome {
	padding: 12px 32px 22px;
	font-weight: bold;
	font-size: 24px;
	margin: 0;
}

.count-data {
	background: #fff;
	border-radius: 8px 8px 8px 8px;
	padding: 20px 30px;

	.data-title {
		font-size: 18px;
		color: #3A3F63;
		margin: 0 0 20px;
		font-weight: bold;
	}

	.counts {
		display: flex;
		justify-content: space-between;

		.item {
			display: flex;
			align-items: center;

			.icon {
				width: 60px;
				height: 60px;
				margin-right: 20px;
			}

			.info {
				display: flex;
				flex-direction: column;

				.title {
					color: #6E7385;
					margin-bottom: 8px;
				}

				.nums {
					color: #333333;
					font-size: 22px;
					font-weight: bold;
				}
			}
		}
	}
}

.chart-div {
	margin-top: 20px;
	display: flex;

	.domain-name {
		flex: 0.7;
		background: #fff;
		border-radius: 8px 8px 8px 8px;
		margin-right: 18px;
		padding: 20px 30px;
		display: flex;
		flex-direction: column;

		p.title {
			font-size: 18px;
			color: #3A3F63;
			margin: 0 0 20px;
			font-weight: bold;
		}

		.chart {
			display: flex;
		}
	}

	.service-info {
		flex: 0.3;
		background: #fff;
		border-radius: 8px 8px 8px 8px;
		padding: 20px 30px;

		p.title {
			font-size: 18px;
			color: #3A3F63;
			margin: 0 0 20px;
			font-weight: bold;
		}

		.chart {
			display: flex;
		}
	}
}

.info-table {
	background: #fff;
	border-radius: 8px 8px 8px 8px;
	padding: 20px 30px;
	margin-top: 20px;

	p.title {
		font-size: 18px;
		color: #3A3F63;
		margin: 0 0 20px;
		font-weight: bold;
	}

	.pagination {
		display: flex;
		justify-content: flex-end;
		padding-top: 20px;
	}
}
</style>

<style>
.board .el-table th.el-table__cell.is-leaf {
	background: #FAFAFA;
}
</style>
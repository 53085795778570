import axios from 'axios'
import {ElMessage, ElMessageBox} from 'element-plus'
import config from "@/config";
import {useRouter} from "vue-router";

const router = useRouter()

const service = axios.create({
    baseURL: config.baseUrl,
    timeout: 30000, // 请求超时时间，设置15秒。有些接口事务多能会长
})

// 请求发起拦截
service.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('imtoken')
        if (token) config.headers.Authorization = token
        return config
    },
    (error) => {
        console.log(error) // for debug
        return Promise.reject(error)
    },
)

// 请求响应拦截
service.interceptors.response.use(
    (response) => {
        const res = response.data
        if (res.code !== 0) {
            if (res.code === 401) {
                ElMessageBox.confirm('登录信息失效，请重新登录', '确定登出', {
                    confirmButtonText: '重新登录',
                    cancelButtonText: '取消',
                    showCancelButton: false,
                    type: 'warning',
                }).then(() => {
                    router.push('/')
                })
            } else {
                console.log(res)
                ElMessage({
                    message: `ERROR: ${res.msg}` || 'Error',
                    type: 'error',
                    duration: 5 * 1000,
                })
            }
            return Promise.reject(res)
        } else {
            return res
        }
    },
    (error) => {
        let {message} = error
        if (message === 'Network Error') {
            message = '接口连接异常'
        } else if (message.includes('timeout')) {
            message = '系统接口请求超时'
        } else if (message.includes('Request failed with status code')) {
            message = '系统接口' + message.substr(message.length - 3) + '异常'
        }
        console.log(error)
        const response = error.response
        ElMessage({
            message: response.data && response.data.msg || message,
            type: 'error',
            duration: 5 * 1000,
        })
        return Promise.reject(error)
    },
)

export default service

import {ElMessage} from "element-plus";
import {getUserInfo} from "@/api/user";

const state = () => {
    return {
        token: '',
        userInfo: {}
    }
}

const getters = {
    token: state => {
        const localToken = localStorage.getItem('imtoken')
        if (!state.token && localToken) {
            state.token = localToken
        }
        return state.token
    },
    userInfo: state => {
        const localUserInfo = localStorage.getItem('userInfo')
        if (!state.userInfo._id && localUserInfo) {
            state.userInfo = JSON.parse(localUserInfo)
        }
        return state.userInfo
    }
}

const mutations = {
    setToken(state, token) {
        state.token = token
        localStorage.setItem('imtoken', token)
    },
    setUserInfo(state, userInfo) {
        state.userInfo = userInfo
        localStorage.setItem('userInfo', JSON.stringify(userInfo))
    }
}

const actions = {
    getUser: async ({commit}) => {
        try {
            const res = await getUserInfo()
            if (res.code === 200) {
                commit('setUserInfo', res.data)
            }
        } catch (e) {
            ElMessage.error(e.message)
        }
    }
}

export default {
    state,
    getters,
    mutations,
    actions,
    namespace: true
}
<script setup>
import {ref} from 'vue'
import {useStore} from "vuex";
import {ElMessage} from "element-plus";

const store = useStore()
import {useRouter} from "vue-router";
import {customerServiceAuth} from "@/api/user";

const form = ref({})
const router = useRouter()

const toIndex = () => {
	router.push('/board')
}

const usernameErr = ref('')
const passwordErr = ref('')
const toLogin = async () => {
	if (!form.value.username) {
		usernameErr.value = '请输入账号'
		return
	}
	if (!form.value.password) {
		passwordErr.value = '请输入密码'
		return
	}
	try {
		const {data, token} = await customerServiceAuth({
			username: form.value.username,
			password: form.value.password,
			identityType: '1',
		})
		ElMessage.success('登录成功')
		store.commit('setToken', token)
		store.commit('setUserInfo', data)
		toIndex()
	} catch (e) {
		console.log(e)
	}
}
</script>

<template>
	<div class="login-page">
		<div class="login-form">
			<div class="content">
				<p class="web-title">IM-后台管理</p>
				<p class="web-name">Welcome to IM-Backstage management</p>
				<div class="form">
					<div class="form-item">
						<p class="label">账号</p>
						<el-input class="input" v-model="form.username" placeholder="请输入邮箱"/>
						<p class="err-tips" v-if="usernameErr">{{ usernameErr }}</p>
					</div>
					<div class="form-item">
						<p class="label">密码</p>
						<el-input class="input" type="password" v-model="form.password" placeholder="请输入密码"/>
						<p class="err-tips" v-if="passwordErr">{{ passwordErr }}</p>
					</div>
				</div>
				<div class="btn-box">
					<el-button class="login-btn" type="primary" round @click="toLogin">立即登录</el-button>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">
.login-page {
	display: flex;
	height: 100vh;
	overflow: hidden;
	background: #F5F5FA;
}

.login-page .login-form {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;

	.content {
		margin: 0 auto 70px;

		.web-title {
			height: 40px;
			font-weight: bold;
			font-size: 36px;

			img {
				width: 100%;
				height: 100%;
			}
		}

		.web-name {
			font-size: 16px;
			color: #6A74A5;
			margin-bottom: 60px;
		}

		.form {
			.form-item {
				margin-bottom: 30px;
			}

			.label {
				font-size: 16px;
				color: #3A3F63;
			}

			.err-tips {
				color: #FF2855;
				font-size: 13px;
				position: relative;
				top: -11px;
			}

			.input {
				height: 50px;
				font-size: 16px;
			}
		}

		.login-btn {
			background: linear-gradient(45deg, #4F8AFF 0%, #4B5EFF 100%);
			box-shadow: 0px 4px 16px 0px #B3C0E7;
			border-radius: 27px 27px 27px 27px;
			width: 360px;
			height: 50px;
			margin-top: 20px;
		}
	}
}

.login-img {
	flex: .45;

	img {
		width: 100%;
		height: 100%;
	}
}
</style>
<script setup>
import {computed, onMounted, ref} from "vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import {editAdminUser} from "@/api/user";
import {ElMessage} from "element-plus";

const store = useStore()
const router = useRouter()

const userInfo = computed(() => store.getters.userInfo)

const command = (command) => {
	console.log(command)
	if (command === 'b') {
		router.push('/')
	}
	if (command === 'a') { // 个人信息编辑
		form.value = {}
		form.value.nickname = userInfo.value.nickname
		form.value._id = userInfo.value._id
		showDialog.value = true
	}
}
// 修改信息
const showDialog = ref(false)
const form = ref({})
const submit = async () => {
	if (!form.value.password || !form.value.oldPassword) {
		delete form.value.password
		delete form.value.oldPassword
	}
	try {
		const update = await editAdminUser(form.value)
		if (update) {
			store.dispatch('getUser')
			showDialog.value = false
			if (form.value.password && form.value.oldPassword) {
				router.push('/')
				ElMessage.success('修改成功,请重新登录！')
			} else {
				ElMessage.success('修改成功')
			}
		}
	} catch (e) {
		console.log(e)
	}
}

onMounted(function () {
	if (!userInfo.value) {
		router.push('/')
	}
})
</script>

<template>
	<div class="header">
		<div class="web-title">
			IM-后台管理
		</div>
		<div class="info">
			<el-icon class="notice-icon">
				<Bell/>
			</el-icon>
			<div class="name">
				<el-dropdown trigger="click" @command="command">
					<div>
						<span v-if="userInfo && userInfo.username">{{ userInfo.username.substr(0, 1).toUpperCase() }}</span>
						<span v-if="userInfo && userInfo.nickname">{{ userInfo.nickname || '未知用户' }}</span>
					</div>
					<template #dropdown>
						<el-dropdown-menu>
							<el-dropdown-item command="a">个人信息</el-dropdown-item>
							<el-dropdown-item command="b">退出登录</el-dropdown-item>
						</el-dropdown-menu>
					</template>
				</el-dropdown>
			</div>
		</div>
	</div>
	<!-- 编辑个人信息dialog	-->
	<el-dialog v-model="showDialog" title="个人信息" width="500px">
		<el-form :model="form" ref="formRef" label-width="70px" v-if="userInfo && userInfo._id">
			<el-form-item label="用户ID" prop="username">
				<el-input :value="userInfo._id" disabled placeholder="请输入用户名"></el-input>
			</el-form-item>
			<el-form-item label="用户名" prop="username">
				<el-input
						:value="userInfo.username"
						disabled
						placeholder="请输入用户名"></el-input>
			</el-form-item>
			<el-form-item label="昵称" prop="nickname">
				<el-input v-model="form.nickname" placeholder="请输入昵称"></el-input>
			</el-form-item>
			<el-divider content-position="left">修改密码</el-divider>
			<el-form-item label="旧密码" prop="oldPassword">
				<el-input v-model="form.oldPassword" placeholder="请输入旧密码"></el-input>
			</el-form-item>
			<el-form-item label="新密码" prop="newPassword">
				<el-input v-model="form.password" placeholder="请输入新密码"></el-input>
			</el-form-item>
		</el-form>
		<template #footer>
			<span class="dialog-footer">
				<el-button @click="showDialog = false">取 消</el-button>
				<el-button type="primary" @click="submit">修 改</el-button>
			</span>
		</template>
	</el-dialog>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.header {
	display: flex;
	align-items: center;
	background: #fff;
	box-shadow: 0 4px 16px 0 rgba(179, 192, 231, 0.16);
	height: 60px;
	padding: 0 32px;
	box-sizing: border-box;
	border-bottom: 1px solid #e4e6ec;
}

.web-title {
	flex: 0 0 270px;
	height: 30px;
	font-weight: bold;
	font-size: 22px;

	img {
		width: 100%;
		height: 100%;
	}
}

.info {
	flex: 1;
	display: flex;
	justify-content: flex-end;
	align-items: center;

	.lang {
		color: #3A3F63;
		padding: 0 30px;
	}

	.notice-icon {
		color: #71A0FE;
	}

	.name {
		cursor: pointer;

		span:nth-child(1) {
			display: inline-block;
			width: 32px;
			height: 32px;
			background: #71A0FE;
			border-radius: 100%;
			color: #fff;
			text-align: center;
			line-height: 32px;
			margin: 0 8px 0 30px;
		}

		span:nth-child(2) {
			color: #3A3F63;
		}
	}
}
</style>

import service from "@/utils/ajax";

export const getKeywordList = (params) => {
    return service(
        {
            url: '/v1/keyword/getKeywordList',
            method: 'get',
            params
        }
    )
}

export const delKeyword = (data) => {
    return service(
        {
            url: '/v1/keyword/delKeyword',
            method: 'post',
            data
        }
    )
}

export const addKeyword = (data) => {
    return service(
        {
            url: '/v1/keyword/addKeyword',
            method: 'post',
            data
        }
    )
}

export const updateKeyword = (data) => {
    return service(
        {
            url: '/v1/keyword/updateKeyword',
            method: 'post',
            data
        }
    )
}

export const getEsKeywordMsgList = (params) => {
    return service(
        {
            url: '/v1/keyword/getEsKeywordMsgList',
            method: 'get',
            params
        }
    )
}